<template>
  <div class="news">
    <h1 class="title is-2">{{ $t('news.news') }}</h1>
    <VueRssFeed :feedUrl="feedUrl" name="" :limit="5"/>
  </div>
</template>

<script>
import VueRssFeed from "vue-rss-feed";

export default{
  name: 'News',
  data(){
    return {
      feedUrl: 'https://dwh.lequipe.fr/api/edito/rss?path=/Cyclisme/',
    };
  },
  components: {
    VueRssFeed
  },
};
</script>

<style lang="scss">
.feed .footer{ /*remove defined footer style */
  padding: 0;
  background-color: white;
}
</style>
